import React from "react"
import styles from "./Badge.module.css"
import { FaFigma } from "@react-icons/all-files/fa/FaFigma"
import { FaSketch } from "@react-icons/all-files/fa/FaSketch"
import BlenderIcon from "./blender.svg"

const Badge: React.FC<{
  icon?: string
  color?: string
  className?: string
}> = ({ children, icon, color, className }) => {
  let $icon: React.ReactNode = null
  switch (icon) {
    case "figma":
      $icon = <FaFigma className={styles.icon} />
      break
    case "sketch":
      $icon = <FaSketch className={styles.icon} />
      break
    case "blender":
      $icon = (
        <img src={BlenderIcon} width="18px" style={{ marginRight: "5px" }} />
      )
      break
  }
  return (
    <div className={`${styles.badge} ${className || ""}`} data-color={color}>
      {$icon}
      {children}
    </div>
  )
}

export default Badge
