import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import React from "react"
import { Product } from "../../types/product"
import styles from "./ProductCard.module.css"
import Image from "gatsby-image"
import Badge from "../Badge/Badge"
import { price } from "../../utils"

const ProductCard: React.FC<{ product: Product }> = ({ product }) => {
  const {
    cardAllFile: { edges: cardImages },
  } = useStaticQuery(graphql`
    {
      cardAllFile: allFile(
        filter: {
          name: { eq: "card" }
          relativeDirectory: { regex: "/products/*/" }
        }
      ) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const {
    node: {
      childImageSharp: { fluid: cardImage },
    },
  } = cardImages.find(({ node: { relativePath } }) =>
    relativePath.includes(`/${product.id}/`)
  )
  return (
    <div
      className={styles.productCard}
      onClick={() => navigate(`/${product.slug}`)}
    >
      <div className={styles.imageWrap}>
        <Image fluid={cardImage} className={styles.image} />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.name}>{product.name}</div>
          <Badge color="primary">{price(product.price)}</Badge>
        </div>
        <div className={styles.description}>
          Compatible with {product.compatible.join(", ")}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
