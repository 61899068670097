import React from "react"
import { Product } from "../../types/product"
import ProductCard from "../ProductCard/ProductCard"
import styles from "./Products.module.css"

const Products: React.FC<{ products: Product[] }> = ({ products }) => {
  return (
    <div className={styles.products}>
      {products.map(product => (
        <div key={product.id} className={styles.product}>
          <ProductCard product={product} />
        </div>
      ))}
    </div>
  )
}

export default Products
