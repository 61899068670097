import React from "react"
import { Category } from "../../types/category"
import styles from "./CategoryProducts.module.css"
import productsJSON from "../../data/products.json"
import categoriesJSON from "../../data/categories.json"
import Products from "../Products/Products"
import { Product } from "../../types/product"

const CategoryProducts: React.FC<{
  categoryId: Category["id"]
  header?: string
  exclude?: Product["id"][]
}> = ({ categoryId, header, exclude }) => {
  const category = categoriesJSON.categories.find(({ id }) => id === categoryId)
  if (!category) {
    return null
  }
  const products = productsJSON.products.filter(
    ({ categories, id }) =>
      categories.includes(category.id) && !exclude?.includes(id)
  )
  if (!products.length) {
    return null
  }
  return (
    <div className={styles.categoryProducts}>
      <div className={styles.header}>{header || category.name}</div>
      <div className={styles.products}>
        <Products products={products} />
      </div>
    </div>
  )
}

export default CategoryProducts
